import React, { Component } from "react";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.success = React.createRef();
    this.state = {
      messageSent: false,
    };
  }

  render() {
    const handleSubmit = (e) => {
      const form = document.getElementById("contactForm");
      const formElements = form.querySelectorAll(".c-form__input");
      const inputData = [...formElements].map((item) => {
        return item.value;
      });

      const http = new XMLHttpRequest();
      const url = "handle_data.php";
      const params = inputData;
      http.open("POST", url, true);

      //Send the proper header information along with the request
      http.setRequestHeader(
        "Content-type",
        "application/x-www-   form-urlencoded"
      );

      http.onreadystatechange = () => {
        //Call a function when the state changes.
        if (http.readyState === 4 && http.status === 200) {
          this.setState({ messageSent: true });
          form.reset();
        }
      };
      http.send(params);
      // prevent scroll top behaviour on send?
      e.preventDefault();

      return false;
    };

    return (
      <section id="contact" className="c-contact">
        <div className="c-row c-contact__header"></div>

        <div className="c-row">
          <div className="c-contact__lead">
            <h3 className="c-contact__headline">
              <span className="c-contact__headline-content">Get In Touch.</span>
            </h3>
          </div>
          <div className="c-contact__form">
            <form
              //action=""
              className="c-form"
              //method="post"
              onSubmit={handleSubmit}
              id="contactForm"
              name="contactForm"
            >
              <div className="c-form__fieldset">
                <div className="c-form__fieldset-item">
                  <div>
                    <label className="c-form__label" htmlFor="contactName">
                      Name <span className="c-form__required">*</span>
                    </label>
                    <input
                      className="c-form__input"
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label className="c-form__label" htmlFor="contactEmail">
                      Email <span className="c-form__required">*</span>
                    </label>
                    <input
                      className="c-form__input"
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label className="c-form__label" htmlFor="contactSubject">
                      Subject
                    </label>
                    <input
                      className="c-form__input"
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="c-form__fieldset-item">
                  <label className="c-form__label" htmlFor="contactMessage">
                    Message <span className="c-form__required">*</span>
                  </label>
                  <textarea
                    className="c-form__textarea"
                    cols="50"
                    rows="14"
                    id="contactMessage"
                    name="contactMessage"
                  ></textarea>
                </div>

                <div className="c-form__fieldset-cta">
                  <button
                    className="submit c-form__button"
                    onClick={handleSubmit}
                    ref={this.formRef}
                    type="submit"
                  >
                    Submit
                  </button>
                  <span id="image-loader">
                    <img alt="loader" src="images/loader.gif" />
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="c-form__error">
            {" "}
            Error occured please write an Email instead.
          </div>
          {this.state.messageSent ? (
            <div ref={this.success} className="c-form__success">
              <i className="fa fa-check"></i>Your message was sent, thank you!
              I'll respond as soon as possible.
              <br />
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

export default Contact;
