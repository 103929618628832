import React, { Component } from "react";
import { Typing } from "./Typing/Typing";
import backgroundImage from "../images/header-background.jpg";

const navItems = [
  {
    name: "Home",
    linkTo: "home",
    current: true,
  },
  {
    name: "About",
    linkTo: "about",
  },
  {
    name: "Work",
    linkTo: "resume",
  },
  {
    name: "Tech",
    linkTo: "tech",
  },
  {
    name: "Contact",
    linkTo: "contact",
  },
];

const styles = {
  paperContainer: {
    background: `#161415 url(${backgroundImage}) no-repeat top center`,
  },
};

const NavItem = ({ name, linkTo, clickHandler, current }) => {
  return (
    <li className={`c-nav__item ${current ? "current" : ""}`}>
      <a
        className="c-nav__item-link smoothscroll"
        href={`#${linkTo}`}
        onClick={clickHandler}
      >
        {name}
      </a>
    </li>
  );
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeNav() {
    this.setState({
      navOpen: false,
    },
    () => {
      this.state.navOpen
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    });
  }

  clickHandler() {
    this.setState(
      {
        navOpen: !this.state.navOpen,
      },
      () => {
        this.state.navOpen
          ? document.body.classList.add("no-scroll")
          : document.body.classList.remove("no-scroll");
      }
    );
  }

  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var networks = this.props.data.social.map(function (network) {
        return (
          <li className="c-social__item" key={network.name}>
            <a
              className="c-social__item-link"
              target="_blank"
              rel="noopener noreferrer"
              href={network.url}
              alt={network.name}
            >
              <i className={network.className}></i>
            </a>
          </li>
        );
      });
    }

    return (
      <header id="home" className="c-hero" style={styles.paperContainer}>
        <nav id="nav-wrap" className="c-nav__wrapper">
          <div
            className={`backdrop ${
              this.state.navOpen ? "backdrop--active" : ""
            }`}
          ></div>
          <div
            className={`c-mobile-nav ${
              this.state.navOpen ? "c-mobile-nav--open" : "c-mobile-nav--closed"
            }`}
            onClick={this.clickHandler.bind(this)}
          >
            <div className="c-mobile-nav__icon"></div>
          </div>

          <ul
            id="nav"
            className={`c-nav ${
              this.state.navOpen !== undefined
                ? this.state.navOpen
                  ? "c-nav--open"
                  : "c-nav--closed"
                : ""
            }`}
          >
            {navItems.map((item) => (
              <NavItem
                name={item.name}
                linkTo={item.linkTo}
                clickHandler={this.closeNav.bind(this).bind(this)}
                key={`nav-${item.name}`}
              />
            ))}
          </ul>
        </nav>

        <div className="c-row banner c-hero__content">
          <div className="banner-text c-hero__text-section">
            <h1 className="responsive-headline c-hero__headline">{name}</h1>
            <Typing />
            {/*             <h3 className="c-hero__subheadline">
              I'm a developer based <span>{occupation}</span>. {description}.
            </h3> */}
            {/*  <hr className="c-hero__hr" /> */}
            <ul className="social c-social">{networks}</ul>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
