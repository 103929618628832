export const techData = [
  {
    name: "HTML",
    svg: "html-5.svg",
  },
  {
    name: "CSS",
    svg: "css-3.svg",
  },
  {
    name: "JavaScript",
    svg: "javascript.svg",
  },
  {
    name: "TypeScript",
    svg: "typescript.svg",
  },
  {
    name: "React",
    svg: "react.svg",
  },
  {
    name: "Angular",
    svg: "angular.svg",
  },
  {
    name: "nodeJS",
    svg: "node.svg",
  },
  {
    name: "jquery",
    svg: "jquery.svg",
  },
  {
    name: "Sass",
    svg: "sass.svg",
  },
  {
    name: "Stylus",
    svg: "stylus.svg",
  },
  {
    name: "Git",
    svg: "git.svg",
  },
  {
    name: "NPM",
    svg: "npm.svg",
  },
  {
    name: "Webpack",
    svg: "webpack.svg",
  },
  {
    name: "Wordpress",
    svg: "wordpress.svg",
  },
  {
    name: "Bootstrap",
    svg: "bootstrap.svg",
  },
  {
    name: "Cypress",
    svg: "cypress.svg",
  },
];
