import React from "react";

export function FadeIn(props) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisible(entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(domRef.current);
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""} ${
        props.classes
      }`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
