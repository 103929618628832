import React, { useEffect, useRef } from "react";

export const Typing = () => {
  const textNode = useRef();

  useEffect(() => {
    changingCharacters(typeIntro);
  }, []);

  const typeIntro = () => {
    let headline = document.querySelector(".home__headline");
    const introText = ``;

    let count = 0;
    let index = 0;

    const typingEffect = () => {
      const textArray = [
        "and JavaScript expert.",
        "with passion and determination.",
        "and React enthusiast."
      ];

      let text = textArray[index];

      if (count < text.length) {
        setTimeout(function() {
          headline.innerHTML += text[count];
          count++;
          typingEffect();
        }, Math.floor(Math.random() * 160));
      } else {
        count = 0;
        index = index + 1;
        if (index < textArray.length) {
          setTimeout(function() {
            headline.innerHTML = introText;
            typingEffect();
          }, 2600);
        }
      }
    };
    typingEffect();
  };

  const changingCharacters = cb => {
    const spanNodes = document.querySelectorAll(".home__char");
    const chars = [
      ["a", "v", "s", "h", "F"],
      ["*", "b", "!", "e", "r"],
      ["I", "n", "&", "c", "o"],
      ["", "#", "g", "f", "n"],
      ["", "b", "]", "r", "t"],
      ["", "", "m", "@", "e"],
      ["", "", "g", "<", "n"],
      ["", "", "", "r", "d"]
    ];

    function runIt(charArr) {
      let factor = 1.0;

      for (let y = 0; y < spanNodes.length; y++) {
        let timer = 0;
        let x = 0;
        while (x < charArr[y].length) {
          setTimeout(function() {
            spanNodes[y].innerHTML = charArr[y][timer];
            timer++;
          }, 185 * x * factor);
          x++;
        }
        factor += 0.15;
      }
    }

    runIt(chars);
    if (cb) setTimeout(() => cb(), 2500);
  };

  return (
    <div className="home">
      <div className="home__typing" id="intro-container">
        {/* <h1 className="">Marcel Wiethan |&nbsp;</h1> */}
        <div>
          <span className="home__char">a</span>
          <span className="home__char">a</span>
          <span className="home__char">a</span>
          <span className="home__char"></span>
          <span className="home__char"></span>
          <span className="home__char"></span>
          <span className="home__char"></span>
          <span className="home__char"></span>&nbsp;
          <span className="">Developer&nbsp;</span>
        </div>
        <span
          ref={textNode}
          className="home__headline home__type-effect"
        ></span>
      </div>
    </div>
  );
};
