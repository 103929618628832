import React, { Component } from "react";
import { Tech } from "./Tech/Tech";
import { FadeIn } from "./FadeIn/FadeIn";

class Resume extends Component {
  render() {
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      const workLength = this.props.data.work.length;
      var work = this.props.data.work.map(function(work, index) {
        return (
          <FadeIn key={work.company}>
            <div
              className={
                workLength === index + 1
                  ? "c-work__item u-mar-b--0"
                  : "c-work__item"
              }
            >
              <h3 className="c-work__item-headline">{work.company}</h3>
              <div className="c-work__item-content">
                <p className="c-work__info">
                  {work.title}
                  <span> &bull;</span>{" "}
                  <em className="c-work__date">{work.years}</em>
                </p>
                <ul className="c-work__list">
                  {work.description.map(item => {
                    return (
                      <li
                        className="c-work__list-item"
                        key={`${item.substring(1, 4)}`}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </FadeIn>
        );
      });
    }

    return (
      <>
        <section id="resume" className="c-resume">
          <div className="c-row c-work">
            <h2 className="c-resume__headline section__headline section__headline--alone">
              Work
            </h2>

            <div className="c-work__content">{work}</div>
          </div>
        </section>
        <section className="c-tech__container" id="tech">
          <div className="c-row">
            <h2
              className="
            c-resume__headline section__headline"
            >
              Technologies
            </h2>

            <div className="main-col">
              <p className="section__subheadline">{skillmessage}</p>
              <Tech />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Resume;
