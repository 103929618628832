import React, { Component } from "react";
import { Legal } from "./Legal/Legal";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalActive: false,
    };
  }

  clickHandler = () => {
    this.setState({
      legalActive: !this.state.legalActive,
    });
  };

  render() {
    if (this.props.data) {
      var networks = this.props.data.social.map(function (network) {
        return (
          <li className="c-social__item" key={network.name}>
            <a
              className="c-social__item-link"
              target="_blank"
              rel="noopener noreferrer"
              href={network.url}
              alt={network.name}
            >
              <i className={network.className}></i>
            </a>
          </li>
        );
      });
    }

    return (
      <>
        {/* {this.state.legalActive ? ( */}
        <Legal
          active={this.state.legalActive}
          closeHandler={this.clickHandler}
        />
        {/* ) : null} */}
        <footer className="c-footer">
          <div className="c-row c-row--center-x">
            <div className="columns">
              <ul className="c-social">{networks}</ul>

              <ul className="c-copyright">
                <li className="c-copyright__item">
                  &copy; Copyright 2021 Marcel Wiethan
                </li>
                <li
                  className="c-copyright__item c-legal__link"
                  onClick={this.clickHandler}
                >
                  Legal information
                </li>
              </ul>
            </div>
            <div id="go-top" className="c-scrolltop">
              <a
                className="c-scrolltop__link smoothscroll"
                title="Back to Top"
                href="#home"
              >
                <i className="icon-up-open icon-up-circle c-scrolltop__icon"></i>
              </a>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
