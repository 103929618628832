import React from "react";

export const Legal = ({ active, closeHandler }) => {
  return (
    <section
      onClick={closeHandler}
      className={`c-legal ${active ? "c-legal--active" : "c-legal--inactive"}`}
    >
      <div className="c-row">
        <div className="c-legal__close"></div>
        <div>
          <h2 className="c-legal__headline section__headline">
            Legal information
          </h2>
          <p>
            Marcel Wiethan
            <br /> Limitation of liability for internal content The content of
            our website has been compiled with meticulous care and to the best
            of our knowledge. However, we cannot assume any liability for the
            up-to-dateness, completeness or accuracy of any of the pages.
            Pursuant to section 7, para. 1 of the TMG (Telemediengesetz – Tele
            Media Act by German law), we as service providers are liable for our
            own content on these pages in accordance with general laws. However,
            pursuant to sections 8 to 10 of the TMG, we as service providers are
            not under obligation to monitor external information provided or
            stored on our website. Once we have become aware of a specific
            infringement of the law, we will immediately remove the content in
            question. Any liability concerning this matter can only be assumed
            from the point in time at which the infringement becomes known to
            us. Limitation of liability for external links Our website contains
            links to the websites of third parties („external links“). As the
            content of these websites is not under our control, we cannot assume
            any liability for such external content. In all cases, the provider
            of information of the linked websites is liable for the content and
            accuracy of the information provided. At the point in time when the
            links were placed, no infringements of the law were recognisable to
            us. As soon as an infringement of the law becomes known to us, we
            will immediately remove the link in question.
          </p>
        </div>
      </div>
    </section>
  );
};
