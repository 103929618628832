import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var email = this.props.data.email;
    }

    return (
      <section id="about" className="c-about">
        <div className="c-row">
          <div className="c-about__pic columns">
            <img
              className="profile-pic c-about__pic-content"
              src={profilepic}
              alt="Marci Profile Pic"
            />
          </div>
          <div className="c-row__item-9">
            <h2 className="u-primary-white">About Me</h2>

            <p>{bio}</p>
            <h2 className="u-primary-white">Contact Details</h2>
            <p>
              <span>{name}</span>
              <br />
              <span>
                {city}, {state}
              </span>
              <br />
              <span>{email}</span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
