import React, { useRef } from "react";
import { techData } from "../../techData";
import { FadeIn } from "../FadeIn/FadeIn";
export const Tech = () => {
  const techNode = useRef();

  return (
    <div ref={techNode} className={`columns c-tech`}>
      {techData.map((item) => {
        return (
          <FadeIn classes="c-tech__item" key={`tech-${item.name}`}>
            <img
              alt={item.name}
              className="c-tech__item-icon"
              src={`images/tech/${item.svg}`}
              loading="lazy"
            ></img>
            {/*item.name*/}
          </FadeIn>
        );
      })}
    </div>
  );
};
