import React, { Component } from "react";

class Testimonials extends Component {
  render() {
    if (this.props.data) {
      var testimonials = this.props.data.testimonials.map(function (
        testimonials
      ) {
        return (
          <li key={testimonials.user}>
            <blockquote>
              <p className="c-testemonials__paragraph">{testimonials.text}</p>
              <cite>{testimonials.user}</cite>
            </blockquote>
          </li>
        );
      });
    }

    return (
      <section id="testimonials" className="c-testemonials">
        <div className="c-row">
          {/*             <div className="two columns header-col">
              <h2 className="c-testemonials__headline">Client Testimonials</h2>
            </div> */}
          <ul className="c-testemonials__list">{testimonials}</ul>
        </div>
      </section>
    );
  }
}

export default Testimonials;
